import { mergeClasses, makeStyles, shorthands } from '@griffel/react';
import { iconFilledClassName, iconRegularClassName } from '@fluentui/react-icons';
import { createFocusOutlineStyle } from '@fluentui/react-tabster';
import { tokens } from '@fluentui/react-theme';
import { useCheckmarkStyles_unstable } from '../../selectable/index';
export const menuItemClassNames = {
    root: 'fui-MenuItem',
    icon: 'fui-MenuItem__icon',
    checkmark: 'fui-MenuItem__checkmark',
    submenuIndicator: 'fui-MenuItem__submenuIndicator',
    content: 'fui-MenuItem__content',
    secondaryContent: 'fui-MenuItem__secondaryContent'
};
const useStyles = makeStyles({
    focusIndicator: createFocusOutlineStyle(),
    // TODO: this should be extracted to another package
    root: {
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        position: 'relative',
        color: tokens.colorNeutralForeground2,
        backgroundColor: tokens.colorNeutralBackground1,
        paddingRight: tokens.spacingVerticalSNudge,
        paddingLeft: tokens.spacingVerticalSNudge,
        paddingTop: tokens.spacingVerticalSNudge,
        boxSizing: 'border-box',
        maxWidth: '290px',
        minHeight: '32px',
        flexShrink: 0,
        display: 'flex',
        alignItems: 'start',
        fontSize: tokens.fontSizeBase300,
        cursor: 'pointer',
        ...shorthands.gap('4px'),
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground1Hover,
            color: tokens.colorNeutralForeground2Hover,
            [`& .${iconFilledClassName}`]: {
                display: 'inline'
            },
            [`& .${iconRegularClassName}`]: {
                display: 'none'
            },
            [`& .${menuItemClassNames.icon}`]: {
                color: tokens.colorNeutralForeground2BrandSelected
            }
        },
        userSelect: 'none'
    },
    content: {
        paddingLeft: '2px',
        paddingRight: '2px',
        backgroundColor: 'transparent',
        flexGrow: 1
    },
    secondaryContent: {
        paddingLeft: '2px',
        paddingRight: '2px',
        color: tokens.colorNeutralForeground3,
        ':hover': {
            color: tokens.colorNeutralForeground3Hover
        },
        ':focus': {
            color: tokens.colorNeutralForeground3Hover
        }
    },
    checkmark: {
        marginTop: '2px'
    },
    icon: {
        width: '20px',
        height: '20px',
        fontSize: '20px',
        lineHeight: 0,
        alignItems: 'center',
        display: 'inline-flex',
        justifyContent: 'center'
    },
    submenuIndicator: {
        width: '20px',
        height: '20px',
        fontSize: '20px',
        lineHeight: 0,
        alignItems: 'center',
        display: 'inline-flex',
        justifyContent: 'center'
    },
    disabled: {
        color: tokens.colorNeutralForegroundDisabled,
        ':hover': {
            color: tokens.colorNeutralForegroundDisabled,
            backgroundColor: tokens.colorNeutralBackground1,
            cursor: 'not-allowed',
            [`& .${iconFilledClassName}`]: {
                display: 'none'
            },
            [`& .${iconRegularClassName}`]: {
                display: 'inline'
            },
            [`& .${menuItemClassNames.icon}`]: {
                color: tokens.colorNeutralForegroundDisabled
            }
        },
        ':focus': {
            color: tokens.colorNeutralForegroundDisabled
        },
        '@media (forced-colors: active)': {
            color: 'GrayText',
            ':hover': {
                color: 'GrayText',
                [`& .${menuItemClassNames.icon}`]: {
                    color: 'GrayText'
                }
            },
            ':focus': {
                color: 'GrayText'
            }
        }
    }
});
/** Applies style classnames to slots */ export const useMenuItemStyles_unstable = (state)=>{
    const styles = useStyles();
    state.root.className = mergeClasses(menuItemClassNames.root, styles.root, styles.focusIndicator, state.disabled && styles.disabled, state.root.className);
    if (state.content) {
        state.content.className = mergeClasses(menuItemClassNames.content, styles.content, state.content.className);
    }
    if (state.checkmark) {
        state.checkmark.className = mergeClasses(menuItemClassNames.checkmark, styles.checkmark, state.checkmark.className);
    }
    if (state.secondaryContent) {
        state.secondaryContent.className = mergeClasses(menuItemClassNames.secondaryContent, !state.disabled && styles.secondaryContent, state.secondaryContent.className);
    }
    if (state.icon) {
        state.icon.className = mergeClasses(menuItemClassNames.icon, styles.icon, state.icon.className);
    }
    if (state.submenuIndicator) {
        state.submenuIndicator.className = mergeClasses(menuItemClassNames.submenuIndicator, styles.submenuIndicator, state.submenuIndicator.className);
    }
    useCheckmarkStyles_unstable(state);
};
